@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedBoldItalic.eot');
    src: local('Config Rounded Bold Italic'), local('ConfigRoundedBoldItalic'),
        url('ConfigRoundedBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedBoldItalic.woff2') format('woff2'),
        url('ConfigRoundedBoldItalic.woff') format('woff'),
        url('ConfigRoundedBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedBold.eot');
    src: local('Config Rounded Bold'), local('ConfigRoundedBold'),
        url('ConfigRoundedBold.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedBold.woff2') format('woff2'),
        url('ConfigRoundedBold.woff') format('woff'),
        url('ConfigRoundedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded Extralight Itali';
    src: url('ConfigRoundedExtralightItalic.eot');
    src: local('Config Rounded Extralight Italic'), local('ConfigRoundedExtralightItalic'),
        url('ConfigRoundedExtralightItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedExtralightItalic.woff2') format('woff2'),
        url('ConfigRoundedExtralightItalic.woff') format('woff'),
        url('ConfigRoundedExtralightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedExtrabold.eot');
    src: local('Config Rounded Extrabold'), local('ConfigRoundedExtrabold'),
        url('ConfigRoundedExtrabold.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedExtrabold.woff2') format('woff2'),
        url('ConfigRoundedExtrabold.woff') format('woff'),
        url('ConfigRoundedExtrabold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedExtraboldItalic.eot');
    src: local('Config Rounded Extrabold Italic'), local('ConfigRoundedExtraboldItalic'),
        url('ConfigRoundedExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedExtraboldItalic.woff2') format('woff2'),
        url('ConfigRoundedExtraboldItalic.woff') format('woff'),
        url('ConfigRoundedExtraboldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedBlack.eot');
    src: local('Config Rounded Black'), local('ConfigRoundedBlack'),
        url('ConfigRoundedBlack.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedBlack.woff2') format('woff2'),
        url('ConfigRoundedBlack.woff') format('woff'),
        url('ConfigRoundedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedBlackItalic.eot');
    src: local('Config Rounded Black Italic'), local('ConfigRoundedBlackItalic'),
        url('ConfigRoundedBlackItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedBlackItalic.woff2') format('woff2'),
        url('ConfigRoundedBlackItalic.woff') format('woff'),
        url('ConfigRoundedBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedLightItalic.eot');
    src: local('Config Rounded Light Italic'), local('ConfigRoundedLightItalic'),
        url('ConfigRoundedLightItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedLightItalic.woff2') format('woff2'),
        url('ConfigRoundedLightItalic.woff') format('woff'),
        url('ConfigRoundedLightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedLight.eot');
    src: local('Config Rounded Light'), local('ConfigRoundedLight'),
        url('ConfigRoundedLight.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedLight.woff2') format('woff2'),
        url('ConfigRoundedLight.woff') format('woff'),
        url('ConfigRoundedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedMedium.eot');
    src: local('Config Rounded Medium'), local('ConfigRoundedMedium'),
        url('ConfigRoundedMedium.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedMedium.woff2') format('woff2'),
        url('ConfigRoundedMedium.woff') format('woff'),
        url('ConfigRoundedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedMediumItalic.eot');
    src: local('Config Rounded Medium Italic'), local('ConfigRoundedMediumItalic'),
        url('ConfigRoundedMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedMediumItalic.woff2') format('woff2'),
        url('ConfigRoundedMediumItalic.woff') format('woff'),
        url('ConfigRoundedMediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedExtralight.eot');
    src: local('Config Rounded Extralight'), local('ConfigRoundedExtralight'),
        url('ConfigRoundedExtralight.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedExtralight.woff2') format('woff2'),
        url('ConfigRoundedExtralight.woff') format('woff'),
        url('ConfigRoundedExtralight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedItalic.eot');
    src: local('Config Rounded Italic'), local('ConfigRoundedItalic'),
        url('ConfigRoundedItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedItalic.woff2') format('woff2'),
        url('ConfigRoundedItalic.woff') format('woff'),
        url('ConfigRoundedItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedRegular.eot');
    src: local('Config Rounded Regular'), local('ConfigRoundedRegular'),
        url('ConfigRoundedRegular.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedRegular.woff2') format('woff2'),
        url('ConfigRoundedRegular.woff') format('woff'),
        url('ConfigRoundedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedThin.eot');
    src: local('Config Rounded Thin'), local('ConfigRoundedThin'),
        url('ConfigRoundedThin.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedThin.woff2') format('woff2'),
        url('ConfigRoundedThin.woff') format('woff'),
        url('ConfigRoundedThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedThinItalic.eot');
    src: local('Config Rounded Thin Italic'), local('ConfigRoundedThinItalic'),
        url('ConfigRoundedThinItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedThinItalic.woff2') format('woff2'),
        url('ConfigRoundedThinItalic.woff') format('woff'),
        url('ConfigRoundedThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded Text';
    src: url('ConfigRoundedText.eot');
    src: local('Config Rounded Text'), local('ConfigRoundedText'),
        url('ConfigRoundedText.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedText.woff2') format('woff2'),
        url('ConfigRoundedText.woff') format('woff'),
        url('ConfigRoundedText.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded Text';
    src: url('ConfigRoundedTextItalic.eot');
    src: local('Config Rounded Text Italic'), local('ConfigRoundedTextItalic'),
        url('ConfigRoundedTextItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedTextItalic.woff2') format('woff2'),
        url('ConfigRoundedTextItalic.woff') format('woff'),
        url('ConfigRoundedTextItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedSemibold.eot');
    src: local('Config Rounded Semibold'), local('ConfigRoundedSemibold'),
        url('ConfigRoundedSemibold.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedSemibold.woff2') format('woff2'),
        url('ConfigRoundedSemibold.woff') format('woff'),
        url('ConfigRoundedSemibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Config Rounded';
    src: url('ConfigRoundedSemiboldItalic.eot');
    src: local('Config Rounded Semibold Italic'), local('ConfigRoundedSemiboldItalic'),
        url('ConfigRoundedSemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('ConfigRoundedSemiboldItalic.woff2') format('woff2'),
        url('ConfigRoundedSemiboldItalic.woff') format('woff'),
        url('ConfigRoundedSemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}
